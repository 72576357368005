<script lang="ts" setup>
import {ref} from "vue";
import {SliderImage} from "../../_common/composables/slider/types";

const localeRoute = useLocaleRoute();
const {t} = useI18n();
const route = useRoute()

useSeoMeta({
  title: t("pages.meta.title"),
  description: t("pages.meta.description"),

  ogTitle: t("pages.meta.ogTitle"),
  ogDescription: t("pages.meta.ogDescription"),
  ogImage: "/images/hero/about.jpg",
  ogImageAlt: t("pages.meta.ogImageAlt"),
  ogType: 'website', // or 'article', 'product', etc.
  ogSiteName: t("pages.meta.ogTitle"),
  twitterCard: 'summary_large_image',

  // technical
  robots: 'index, follow',
  viewport: 'width=device-width, initial-scale=1',
  charset: 'utf-8',
  lang: t("general.lang"),
});

const tocLinks = [
  {id: "welcome"},
  {id: "expertise"},
  {id: "team"},
  {id: "contact-form"},
];

definePageMeta({
  layout: false,
});

const images: SliderImage[] = [
  {
    src: "/images/hero/resized_about.jpg",
    title: t("home.heroSlider.about.title"),
    text: t("home.heroSlider.about.text"),
    buttonText: t("home.heroSlider.about.buttonText"),
    altText: t("home.heroSlider.about.alt"),
    buttonBackground: "primary",
    internalLink: localeRoute({name: "imprint", hash: "#contact-form"}),
  },
  {
    src: "/images/hero/resized_sphere-with-dots-and-lines.jpg",
    title: t("home.heroSlider.ai.title"),
    text: t("home.heroSlider.ai.text"),
    altText: t("home.heroSlider.ai.alt"),
    buttonText: t("button.learn-more-about", [
      t("home.heroSlider.ai.buttonText"),
    ]),
    externalLink: "https://saxony.ai",
  },
  {
    src: "/images/hero/application_presentation.jpg",
    title: t("home.heroSlider.softwareDev.title"),
    text: t("home.heroSlider.softwareDev.text"),
    altText: t("home.heroSlider.softwareDev.alt"),
    buttonText: t("button.learn-more-about", [
      t("home.heroSlider.softwareDev.buttonText"),
    ]),
    internalLink: localeRoute({
      name: "software-development",
    }),
  },
  {
    src: "/images/hero/resized_Stuck_Slider.jpg",
    title: t("home.heroSlider.stuckProject.title"),
    text: t("home.heroSlider.stuckProject.text"),
    altText: t("home.heroSlider.stuckProject.alt"),
    buttonText: t("button.learn-more-about", [
      t("home.heroSlider.stuckProject.buttonText"),
    ]),
    internalLink: localeRoute({name: "expertise"}),
  },
  {
    src: "/images/hero/resized_Old_Projects_Slider.jpg",
    title: t("home.heroSlider.oldProjects.title"),
    text: t("home.heroSlider.oldProjects.text"),
    altText: t("home.heroSlider.oldProjects.alt"),
    buttonText: t("button.learn-more-about", [
      t("home.heroSlider.oldProjects.buttonText"),
    ]),
    internalLink: localeRoute({name: "expertise"}),
  },
  {
    src: "/images/hero/resized_roberto-cortese.png",
    title: t("home.heroSlider.shopware.title"),
    text: t("home.heroSlider.shopware.text"),
    altText: t("home.heroSlider.shopware.alt"),
    buttonText: t("button.learn-more-about", [
      t("home.heroSlider.shopware.buttonText"),
    ]),
    internalLink: localeRoute({name: "shopware"}),
  },
];

const contentContainer = ref<null | HTMLElement>(null);
const {activeTableOfContentsItemId} = useTableOfContent(contentContainer);
</script>

<template>
  <NuxtLayout
      name="toc-sidebar"
      :activeTableOfContentsItemId="activeTableOfContentsItemId"
      :tocLinks="tocLinks"
      page="home"
  >
    <div class="" ref="contentContainer">
      <section-component
          :headingLevel=1
          :anchor-tag-title="t('home.landingPage.anchorTagTitle')"
          id="welcome"
      >
        <HeroSlider
            :slider-items="images"
            buttonColor="primary-darken-2"
            subHeadingClass="text-primary-darken-2"
        >
          <template #title>
            <h1 class="text-h1" v-html="t('home.heroSlider.title')" />
          </template>
          <template #subtitle>
            <div v-html="t('home.heroSlider.subTitle')"></div>
          </template>
        </HeroSlider>

        <card-component
            :excerpt="t('home.about.subtitle')"
            :order="1"
            :title="t('home.about.title')"
            :headingLevel=2
            class="d-flex justify-center align-center"
            :transparent="false"
            slug="company"
            style="flex: 1"
            :to="localeRoute({ name: 'about' })"
        />

        <navigation-button
            class="mt-8 ml-auto"
            :to="localeRoute({ name: 'about' })"
        >
          {{ t("button.learn-more-about", [t("home.about.navigationText")]) }}
        </navigation-button>
      </section-component>

      <section-component id="expertise" subHeadingClass="text-primary-darken-3">
        <template #title>
          <h2 class="text-h1">
            {{ t("home.expertise.title") }}
          </h2>
        </template>

        <template #subtitle>
          {{ t("home.expertise.subtitle") }}
        </template>

        <div class="hw-list">
          <card-component
              slug="shopware"
              card-color="1574ad"
              :excerpt="t('technologies.shopware.subtitle')"
              :title="t('technologies.shopware.title')"
              :to="
              localeRoute({
                name: 'shopware',
              })
            "
          />

          <card-component
              slug="app-dev"
              card-color="aee1ff"
              :excerpt="t('technologies.appDev.subtitle')"
              :order="3"
              :title="t('technologies.appDev.titleResponsive')"
              :to="
              localeRoute({
                name: 'software-development',
                hash: '#app-dev',
              })
            "
          />

          <card-component
              slug="ai"
              card-color="103d5c"
              :excerpt="t('technologies.artificialIntelligence.subtitle')"
              :title="t('technologies.artificialIntelligence.title')"
              href="https://saxony.ai"
              :external-link="true"
          />

          <card-component
              slug="mobile-apps"
              :excerpt="t('technologies.mobileApps.subtitle')"
              card-color="1fabff"
              :order="3"
              :title="t('technologies.mobileApps.title')"
              :to="
              localeRoute({
                name: 'software-development',
                hash: '#mobile-apps',
              })
            "
          />

          <card-component
              slug="dev-ops"
              :excerpt="t('technologies.devOps.subtitle')"
              :title="t('technologies.devOps.title')"
              card-color="103d5c"
              :to="
              localeRoute({
                name: 'software-development',
                hash: '#dev-ops',
              })
            "
          />
        </div>

        <navigation-button
            class="mt-8 ml-auto"
            :to="localeRoute({ name: 'expertise' })"
        >
          {{
            t("button.learn-more-about", [t("home.expertise.navigationText")])
          }}
        </navigation-button>

        <h3 class="text-h2 text-none text-center">
          {{ t("home.technologies") }}
        </h3>

        <icons-technology class="mx-8" />

        <navigation-button
            class="mt-8 ml-auto"
            :to="localeRoute({ name: 'technologies' })"
        >
          {{ t("button.learn-more-about", [t("home.technologies")]) }}
        </navigation-button>
      </section-component>

      <section-component id="team" subHeadingClass="text-primary-darken-3">
        <template #title>
          <h2 class="text-h1">
            {{ t("home.team.title") }}
          </h2>
        </template>

        <template #subtitle>
          {{ t("home.team.subtitle") }}
        </template>

        <p class="text-center">
          {{ t("home.team.contactsText1") }}
        </p>
        <p class="text-center">
          {{ t("home.team.contactsText2") }}
        </p>
        <div class="hw-grid hw-mb hw-mt-small w-100">
          <employees-project-managers />
        </div>

        <h3 class="text-h2 text-none text-center">
          {{ t("home.team.teamFotoTitle") }}
        </h3>

        <v-card
            class="hw-my-smaller"
            :to="localeRoute({ name: 'employees' })"
            :aria-label="
            t('button.learn-more-about', [t('home.team.navigationText')])
          "
        >
          <HwImage
              src="images/teams/teams_all.jpg"
              object-fit="fill"
              aspect-ratio="2.07"
          >
            <div class="d-flex h-100 flex-column justify-end">
              <div
                  class="text-strong text-center py-5 d-none d-md-block"
                  style="background-color: rgba(var(--v-theme-background), 0.9)"
              >
                {{ t("home.team.teamFotoText1") }} <br />
                {{ t("home.team.teamFotoText2") }}
              </div>
            </div>
          </HwImage>
        </v-card>

        <navigation-button
            :to="localeRoute({ name: 'employees' })"
            class="mt-8 ml-auto"
        >
          {{ t("button.learn-more-about", [t("home.team.navigationText")]) }}
        </navigation-button>

        <contact-form id="contact-form" class="toc-item" />
      </section-component>
    </div>
  </NuxtLayout>
</template>

<i18n>{
  "de": {
    "pages.meta.title": "Individuelle Software und Shopware",
    "pages.meta.description": "Individuelle Softwareentwicklung, professionelle Shopware-Anbindung sowie zuverlässige Betreuung bestehender IT-Projekte – von Entwicklung bis Betrieb alles aus einer Hand.",
    "pages.meta.ogTitle": "Helm & Walter IT-Solutions – Ihr Partner für Shopware und maßgeschneiderte Softwarelösungen",
    "pages.meta.ogDescription": "Individuelle Softwareentwicklung und Shopware-Anbindungen, innovative KI-Lösungen, mobile App-Entwicklung und nahtlose Integration in Ihre vorhandene IT-Landschaft. Helm & Walter IT-Solutions bietet Ihnen alle Kompetenzen in einem Partner.",
    "pages.meta.ogImageAlt": "Skyline von Dresden bei Sonnenuntergang, darüber aufsteigende Linien mit sechseckförmigen Endpunkten im Himmel; ein Sechseck enthält das Logo von Helm & Walter IT-Solutions.",
    "pages.meta.ogSiteName": "Helm & Walter IT-Solutions"
  },
  "en": {
    "pages.meta.title": "Custom Software & Shopware Solutions",
    "pages.meta.description": "Helm & Walter IT-Solutions from Dresden offers tailored software development, professional Shopware integration, and reliable management of legacy IT projects.",
    "pages.meta.ogTitle": "Helm & Walter IT-Solutions – Your Partner for Shopware and Custom Software",
    "pages.meta.ogDescription": "Helm & Walter IT-Solutions specializes in custom application development, Shopware solutions, and dependable ongoing support – all seamlessly from a single source.",
    "pages.meta.ogImageAlt": "Sunset view of Dresden, with multiple hexagon-ended lines rising upward, featuring the Helm & Walter IT-Solutions logo within one hexagon.",
    "pages.meta.ogSiteName": "Helm & Walter IT-Solutions"
  }
}
</i18n>